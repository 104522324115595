import { useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Control, UseFormWatch } from "react-hook-form";
import { IFormInputs } from "../../types";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

// Create an RTL theme
const theme = createTheme({
  direction: "rtl", // Right to left
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "right", // Align text right in all table cells
        },
      },
    },
  },
});

interface Props {
  control: Control<IFormInputs>;
  watch: UseFormWatch<IFormInputs>;
}

export const AllFertilityTreatmentsTable: React.FC<Props> = ({
  control,
  watch,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fertilityTreatment",
  });

  const firstQuestionAnswer = watch("currentlyInIVF");
  useEffect(() => {
    // Check if the array is empty and we are currently in IVF process
    if (fields.length === 0) {
      // Append 5 new entries
      const newEntries = Array.from({ length: 3 }, () => ({
        type: "",
        date: "",
        folliclesCount: 0,
        eggsCount: 0,
        maternalOvulesCount: 0,
        qualityEmryos: 0,
      }));

      append(newEntries);
    }
  }, [append, fields.length]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>תאריך</TableCell>
              <TableCell>סוג טיפול הפוריות</TableCell>
              <TableCell>
                מספר הזקיקים ביום השראת הביוץ <br />{" "}
              </TableCell>
              <TableCell>מספר ביציות שנשאבו</TableCell>

              <TableCell>מספר עוברים טובים להקפאה/ החזרה</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Controller
                    name={`fertilityTreatment.${index}.date`}
                    control={control}
                    render={({ field }) => (
                      <TextField type="date" {...field} size="small" />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`fertilityTreatment.${index}.type`}
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="insemination">הזרעה </MenuItem>
                        <MenuItem value="IVF">IVF </MenuItem>
                        <MenuItem value="ivf_freshEmbryos">
                          IVF עם החזרת עובר טרי
                        </MenuItem>
                        <MenuItem value="ivf_frozenEmbryos">
                          החזרת עובר קפוא
                        </MenuItem>
                        <MenuItem value="frozenEggs">הקפאת ביציות</MenuItem>
                      </Select>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`fertilityTreatment.${index}.eggsCount`}
                    control={control}
                    render={({ field }) => (
                      <TextField type="number" {...field} size="small" />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`fertilityTreatment.${index}.maternalOvulesCount`}
                    control={control}
                    render={({ field }) => (
                      <TextField type="number" {...field} size="small" />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`fertilityTreatment.${index}.qualityEmryos`}
                    control={control}
                    render={({ field }) => (
                      <TextField type="number" {...field} size="small" />
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Button
            type="button"
            onClick={() => append({ name: "", dose: "", startDate: "" })}
            style={{ marginTop: 20 }}
          >
            הוסף תרופה
          </Button> */}
      </ThemeProvider>
    </>
  );
};
