import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";

export const FatsProfile: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("haveHealthyNutrition");
  return (
    <>
      <Typography variant="h6" component="div">
        האם את סובלת מרמה חריגה של אחד מהערכים הנ"ל ?
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={1}
        //   sx={{ maxWidth: "900px" }}
      >
        <Controller
          name="triglyceridesLevels"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
              <FormLabel sx={{ mb: 2 }}>
                טריגליצרידים / <br /> triglycerides{" "}
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="triglyceridesLevels-select-small"
              >
                <MenuItem value={"low"}>נמוך מהנורמה</MenuItem>
                <MenuItem value={"normal"}>בנורמה</MenuItem>
                <MenuItem value={"high"}>מעל הנורמה</MenuItem>
                <MenuItem value={"unkown"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="cholesterol"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
              <FormLabel sx={{ mb: 2 }}>
                סה״כ כולסטרול / <br /> total cholesterol{" "}
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="cholesterol-select-small"
              >
                <MenuItem value={"low"}>נמוך מהנורמה</MenuItem>
                <MenuItem value={"normal"}>בנורמה</MenuItem>
                <MenuItem value={"high"}>מעל הנורמה</MenuItem>
                <MenuItem value={"unkown"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="nonHDL"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
              <FormLabel className="bold-label" htmlFor="nonHDL" sx={{ mb: 2 }}>
                non-HDL
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="nonHDL-select-small"
              >
                <MenuItem value={"low"}>נמוך מהנורמה</MenuItem>
                <MenuItem value={"normal"}>בנורמה</MenuItem>
                <MenuItem value={"high"}>מעל הנורמה</MenuItem>
                <MenuItem value={"unkown"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="LDL"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
              <FormLabel className="bold-label" htmlFor="LDL" sx={{ mb: 2 }}>
                כולסטרול ״רע״ <br />
                LDL
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="LDL-select-small"
              >
                <MenuItem value={"low"}>נמוך מהנורמה</MenuItem>
                <MenuItem value={"normal"}>בנורמה</MenuItem>
                <MenuItem value={"high"}>מעל הנורמה</MenuItem>
                <MenuItem value={"unkown"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="HDL"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
              <FormLabel className="bold-label" htmlFor="HDL" sx={{ mb: 2 }}>
                / כולסטרול ״טוב״ <br />
                HDL
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="HDL-select-small"
              >
                <MenuItem value={"low"}>נמוך מהנורמה</MenuItem>
                <MenuItem value={"normal"}>בנורמה</MenuItem>
                <MenuItem value={"high"}>מעל הנורמה</MenuItem>
                <MenuItem value={"unkown"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Box>
    </>
  );
};
