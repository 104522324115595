import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import { GeneralDiseases } from "./ConditionalQuestions/GeneralDiseases";
import { YourAutoimmuneDiseaseRadio } from "./ConditionalQuestions/YourAutoimmuneDiseaseRadio";
import { Typography } from "@mui/material";

export const ImmuneStatusSection: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography variant="h6" component="div" sx={{ mb: 5 }}>
        האם אובחנת עם אחת מהמחלות הבאות ?
      </Typography>

      <YourAutoimmuneDiseaseRadio control={props.control} watch={props.watch} />
      <GeneralDiseases control={props.control} watch={props.watch} />
    </>
  );
};
