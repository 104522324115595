import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { Checkbox, FormControlLabel, Button, FormGroup } from "@mui/material";

export const YourAutoimmuneDiseaseRadio: React.FC<
  ConditionalFormProps
> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("anyThyroidDisease");
  const secondeQuestionAnswer = props.watch("anyOtherAutoimmune");
  const thirdQuestionAnswer = props.watch("familyHasAutoimmuneDisease");

  return (
    <Box
      sx={{
        width: "100%",
        margin: "15px",
        flexDirection: "column",
        display: "flex",
      }}
    >
      {/* <Typography variant="h6" component="div">
        האם סבלת או שהינך סובלת ממחלה אוטואימונית ?
      </Typography> */}
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          אוטואמיוניות של בלוטת התריס
        </FormLabel>{" "}
        <Controller
          name="anyThyroidDisease"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      {firstQuestionAnswer === "yes" && (
        <FormGroup style={{ marginRight: "50px", marginBottom: "10px" }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אופי המחלה:{" "}
          </FormLabel>{" "}
          <Controller
            name="hashimoto"
            control={props.control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="השימוטו / Hashimoto (היפותירואידיזם/ תת פעילות בלוטת התריס)"
              />
            )}
          />
          <Controller
            name="graves"
            control={props.control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="גרייבס / Graves (היפרתירואידיזם/ פעילות יתר של בלוטת התריס)"
              />
            )}
          />
        </FormGroup>
      )}
      <FormControl component="fieldset" sx={{ mb: 2, mt: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          סוכרת סוג 1 / Type 1 diabetes
        </FormLabel>
        <Controller
          name="anydisbetesType1"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          {" "}
          ויטיליגו (בהקת) / Vitiligo{" "}
        </FormLabel>
        <Controller
          name="anyVitligo"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          מחלות אוטואימוניות של מערכת העיכול <br /> לדוגמה: צליאק / celiac,
          קרוהן / crohn או קוליטיס / colitis
        </FormLabel>
        <Controller
          name="anyCrohnsDisease"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          אטופיק דרמטיטיס / atopic dermatitis
        </FormLabel>
        <Controller
          name="anyAtopicDermatitis"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          אלופציה אראטה / Alopecia Areata
        </FormLabel>{" "}
        <Controller
          name="AlopeciaAreata"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          דלקת מפרקים שגרונית / Rheumatoid Arthritis
        </FormLabel>{" "}
        <Controller
          name="RheumatoidArthritis"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          לופוס זאבת אדמנתית מערכתית /<br /> Systemic Lupus Erythematosus
          (בקיצור, SLE)
        </FormLabel>{" "}
        <Controller
          name="SLE"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          טרשת עור / טרשת רב-מערכתית / <br /> Systemic Sclerosis / Scleroderma
        </FormLabel>
        <Controller
          name="Scleroderma"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          מחלה אוטואימונית אחרת
        </FormLabel>{" "}
        <Controller
          name="anyOtherAutoimmune"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      {secondeQuestionAnswer === "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא צייני את שם המחלה ממנה את סובלת
          </FormLabel>
          <Controller
            name="otherAutoimmuneName"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                <TextField
                  {...field}
                  //   required //error
                  id="outlined-required"
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>
      )}

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם מישהו מבני משפחתך מדרגה ראשונה סובל מן אחת המחלות המופיעות בשאלות
          הקודמות ?
        </FormLabel>{" "}
        <Controller
          name="familyHasAutoimmuneDisease"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      {thirdQuestionAnswer === "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא פרטי מי מבין בני משפחתך חולה ובמה
          </FormLabel>
          <Controller
            name="familyAutoimmuneDiseaseDescription"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">
                  כמה פעמים בשבוע את מתאמנת ?
                </FormLabel> */}

                <TextField
                  {...field}
                  //   required //error
                  id="outlined-required"
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>
      )}
    </Box>
  );
};
