import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const SportActivity: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("anySportActivity");
  return (
    <>
      <Controller
        name="anySportActivity"
        control={props.control}
        render={({ field }) => (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <FormLabel
              className="bold-label"
              htmlFor="haveHealthyNutrition"
              sx={{ mb: 2 }}
            >
              האם את מבצעת פעילות ספורטיבית?
            </FormLabel>
            <Select
              {...field}
              labelId="demo-select-small-label"
              id="demo-select-small"
            >
              <MenuItem value={"yes"}>כן</MenuItem>
              <MenuItem value={"no"}>לא</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      {firstQuestionAnswer === "yes" && (
        <>
          <Controller
            name="sportEffortLevel"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <FormLabel
                  className="bold-label"
                  htmlFor="haveHealthyNutrition"
                  sx={{ mb: 2 }}
                >
                  איך היית מגדירה את עוצמת הפעילות הספורטיבית ?
                </FormLabel>
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                >
                  <MenuItem value={"low"}>פעילות בעצימות נמוכה</MenuItem>
                  <MenuItem value={"medium"}>פעילות בעצימות בינונית</MenuItem>
                  <MenuItem value={"high"}>פעילות בעצימות גבוהה</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="numberOfWorkoutInaWeek"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <FormLabel
                  className="bold-label"
                  htmlFor="haveHealthyNutrition"
                  sx={{ mb: 2 }}
                >
                  כמה פעמים בשבוע את מתאמנת ?
                </FormLabel>

                <TextField
                  {...field}
                  required //error
                  id="outlined-required"
                  size="small"
                />
              </FormControl>
            )}
          />
        </>
      )}
    </>
  );
};
