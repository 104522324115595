import React from "react";
import TextField from "@mui/material/TextField";
import { IFormInputs, ConditionalFormProps } from "../../types";
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormWatch,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

interface IFormInput {
  phoneNumber: string;
}

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PhoneNumber: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
}) => {
  return (
    <FormControl component="fieldset" sx={{ mb: 2 }}>
      <FormLabel component="legend" sx={{ mb: 2 }}>
        מספר טלפון
      </FormLabel>
      <Controller
        name="phoneNumber"
        control={props.control}
        rules={{
          required: true,
          pattern: {
            value: /^[0-9]{3}-?[0-9]{7}$/,
            message: "מספר טלפון לא תקין",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            required //error
            id="outlined-required"
            {...field}
            error={!!error}
            size="small"
            helperText={error ? error.message : null}
          />
        )}
      />
    </FormControl>
  );
};
