import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import { LastFivePregnancies } from "./LastFivePregnancies";
import { StomachSurgery } from "./ConditionalQuestions/StomachSurgery";
import { MedicineForm } from "./MedicineTable";
import { NutritionTable } from "./NutritionTable";
import { Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const BirthBackgroundSection: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("pastPregnancyCount");
  const secondeQuestionAnswer = props.watch("ChemicalPregnancy");

  const LessThanFivePregnancies: boolean = firstQuestionAnswer < 6;
  const headerToShow = LessThanFivePregnancies
    ? "אנא עני על השאלות לכל הריון שהיה לך"
    : "אנא עני על השאלות על חמשת ההריונות האחרונים שלך";

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography variant="h6" component="div">
        רקע מיילדותי
      </Typography>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          מספר הריונות קודמים
        </FormLabel>
        <Controller
          name="pastPregnancyCount"
          control={props.control}
          //  rules={{ required: true, min: 0, max: 100 }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              size="small"
              // label="Number Input"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </FormControl>
      {firstQuestionAnswer > 0 && (
        <>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            {headerToShow}
          </FormLabel>
          {!LessThanFivePregnancies && (
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                כמה הפלות ספונטניות עברת ?
              </FormLabel>
              <Controller
                name="howManySpontaneousMiscarriage"
                control={props.control}
                //  rules={{ required: true, min: 0, max: 100 }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="number"
                    size="small"
                    // label="Number Input"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          )}
          <LastFivePregnancies
            control={props.control}
            watch={props.watch}
            numberOfPregnancies={firstQuestionAnswer}
          />
        </>
      )}

      <Typography variant="h6" component="div">
        הריון כימי הוא למעשה הפלה מוקדמת, סמוך למועד הווסת או מעט לאחר מכן, לאחר
        בדיקת β-hCG (בטא) חיובית , ולפני שהודגם ההריון באולטרסאונד. לאור הסמיכות
        לווסת, הריונות כימיים פעמים רבות מפוספסים.
      </Typography>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם חווית הריונות כימיים או שאת חושבת שאולי פוספסו הריונות כימיים?
        </FormLabel>
        <Controller
          name="ChemicalPregnancy"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      {secondeQuestionAnswer === "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            מספר הריונות כימיים
          </FormLabel>{" "}
          <Controller
            name="ChemicalPregnancyCount"
            control={props.control}
            //  rules={{ required: true, min: 0, max: 100 }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="number"
                size="small"
                // label="Number Input"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </FormControl>
      )}
      <StomachSurgery control={props.control} watch={props.watch} />
    </>
  );
};
