import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";

export const GeneralDiseases: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("skinRash");
  const secondeQuestionAnswer = props.watch("basinPain");

  return (
    <Box
      sx={{
        width: "100%",
        // maxWidth: 500,
        //  margin: "auto",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Divider
        sx={{ backgroundColor: "black", width: "100%", marginBottom: "10px" }}
      />
      <Typography variant="h6" component="div">
        האם את סובלת מהתופעות הבאות ?
      </Typography>
      <FormControl style={{ marginRight: "50px", marginTop: "10px" }}>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            עייפות בלתי מוסברת
          </FormLabel>{" "}
          <Controller
            name="unkownTiredness"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            פריחה עורית הולכת וחוזרת
          </FormLabel>
          <Controller
            name="skinRash"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>

        {firstQuestionAnswer === "yes" && (
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם הפריחה מגיבה לאנטי-היסטמינים?{" "}
            </FormLabel>
            <Controller
              name="isMedicinHelpsSkinRash"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                  <FormControlLabel
                    value="maybe"
                    control={<Radio />}
                    label="לא יודעת"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        )}
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            נטייה לבצקות?
          </FormLabel>{" "}
          <Controller
            name="edemas"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            נטייה לריבוי אפטות בחלל הפה?
          </FormLabel>{" "}
          <Controller
            name="oralAphthae"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            מיגרנות?
          </FormLabel>{" "}
          <Controller
            name="migraines"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            התקפי חום בתדירות גבוהה יחסית?
          </FormLabel>{" "}
          <Controller
            name="oftenFever"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            התנפחות של בלוטות הלימפה?
          </FormLabel>{" "}
          <Controller
            name="lymphNodesIssues"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            כאבי בטן ו/או אי סדירות של היציאות?
          </FormLabel>{" "}
          <Controller
            name="stomachPains"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            כאבים באגן ללא קשר למחזור החודשי?
          </FormLabel>
          <Controller
            name="basinPain"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>

        {secondeQuestionAnswer === "yes" && (
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם נשללו זיהומים של האגן כולל מחלות מין כמו <br /> זיבה /
              Gonorrhoea וקלמידיה / Chlamydia ?
            </FormLabel>
            <Controller
              name="checkedSexDisease"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                  <FormControlLabel
                    value="maybe"
                    control={<Radio />}
                    label="לא יודעת"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        )}

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            יובש קיצוני בעיניים?
          </FormLabel>{" "}
          <Controller
            name="dryEyes"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            האם את סובלת מקרישיות יתר?{" "}
          </FormLabel>{" "}
          <Controller
            name="hypercoagulability"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </FormControl>
    </Box>
  );
};
