import { IFormInputs } from "../../../types";
import { DOCUMENTS } from "../../../types/DocType";

export function getBasicDocList() {
  return [DOCUMENTS.GENERAL_BLOOD, DOCUMENTS.HORMONS, DOCUMENTS.VIROLOGY];
}

const wasPregnant = (data: IFormInputs) => {
  return (
    data.pastPregnancy[0].howEnded != "" &&
    data.pastPregnancy[0].startDate != "" &&
    data.pastPregnancy[0].type != ""
  );
};

const anyAutoimmuneDisease = (data: IFormInputs) => {
  return (
    data.anyThyroidDisease === "yes" ||
    data.anydisbetesType1 === "yes" ||
    data.anyVitligo === "yes" ||
    data.anyCrohnsDisease === "yes" ||
    data.anyAtopicDermatitis === "yes" ||
    data.AlopeciaAreata === "yes" ||
    data.Scleroderma === "yes" ||
    data.anyOtherAutoimmune === "yes"
  );
};

export function getListOfNeededDocs(data: IFormInputs): DOCUMENTS[] {
  // should be set ? might be doubles
  const fullList: DOCUMENTS[] = getBasicDocList();

  // TSH check

  if (data.isNormalTSH === "no") {
    fullList.push(DOCUMENTS.TSH_notNormal);
  } else if (data.isNormalTSH === "maybe") {
    fullList.push(DOCUMENTS.TSH_maybe);
  } else if (data.isNormalTSH === "yes") {
    fullList.push(DOCUMENTS.TSH_normal);
  }

  // triglyceridesLevels levels

  if (data.triglyceridesLevels === "high") {
    fullList.push(DOCUMENTS.TSH_notNormal);
  }

  // cancer
  if (data.isCancer === "pastMyself" || data.isCancer === "currentMyself") {
    fullList.push(DOCUMENTS.ONCOLOGIST);
  }

  // fertility treatments
  if (data.pastIVF === "yes") {
    fullList.push(DOCUMENTS.Ultrasound_each_treatment);
    fullList.push(DOCUMENTS.Hormons_each_treatment);
    fullList.push(DOCUMENTS.summary_fertility_treatment);
  }

  // autoimmuneDisease

  if (anyAutoimmuneDisease(data)) {
    fullList.push(
      DOCUMENTS.Autoimmune_antibodies,
      DOCUMENTS.TSH_Autoimmune,
      DOCUMENTS.Immunologist
    );
  }

  if (data.anyCrohnsDisease === "yes") {
    fullList.push(DOCUMENTS.gastroenterologist_exam);
  }

  // Sex Disease
  if (data.checkedSexDisease === "yes") {
    fullList.push(DOCUMENTS.SexDisease_bloodTest);
  }

  // Past pregnancies

  // had >1 pragnancy]
  if (wasPregnant(data)) {
    fullList.push(DOCUMENTS.pregnancy_summary);
  }

  if (
    data.pastPregnancy.some(
      (pragnany) => pragnany.howEnded === "EarlyMiscarriage"
    )
  ) {
    fullList.push(DOCUMENTS.EarlyMiscarriage_checks);
  }

  if (
    data.pastPregnancy.some(
      (pragnany) => pragnany.howEnded === "LateMiscarriage"
    )
  ) {
    fullList.push(DOCUMENTS.LateMiscarriage_checks);
  }

  if (
    data.pastPregnancy.some((pragnany) => pragnany.howEnded === "earlyBirth")
  ) {
    fullList.push(DOCUMENTS.earlyBirth);
  }

  if (data.pastPregnancy.some((pragnany) => pragnany.howEnded === "birth")) {
    fullList.push(DOCUMENTS.birth);
  }

  if (
    data.pastPregnancy.some(
      (pragnany) => pragnany.howEnded === "IntentionalAbortion"
    )
  ) {
    fullList.push(DOCUMENTS.IntentionalAbortion_checks);
  }

  if (data.pastPregnancy.some((pragnany) => pragnany.type === "insemination")) {
    fullList.push(DOCUMENTS.ultrasound_insemination_treatment);
  }

  if (data.pastPregnancy.some((pragnany) => pragnany.type === "ivf")) {
    fullList.push(DOCUMENTS.ultrasound_ivf_treatment);
    fullList.push(DOCUMENTS.summarty_ivf_treatment);
  }

  // Stomach Surgery

  if (data.anyStomachSurgery === "yes") {
    fullList.push(DOCUMENTS.stomach_suregery_letter);
  }

  if (data.anyUterusTest === "yes") {
    fullList.push(DOCUMENTS.uterus_test);
  }

  if (data.didSpernTest === "yes") {
    fullList.push(DOCUMENTS.sperm_summary_test);
  }

  if (data.stomachSurgeryStickyTest === "yes") {
    fullList.push(DOCUMENTS.stickness_test);
  }

  if (data.didGeneticTest === "yes") {
    fullList.push(DOCUMENTS.genetic_test);
  }

  //״מספר הריונות קודמים״ =0 וגם ״האם את נמצאת כעת בטיפולי פוריות״- התשובה היא ״כן״
  if (!wasPregnant(data) && data.currentlyInIVF === "yes") {
    fullList.push(DOCUMENTS.zeroPragnancy);
    fullList.push(DOCUMENTS.Ultrasound_each_treatment);
    fullList.push(DOCUMENTS.Hormons_each_treatment);
    fullList.push(DOCUMENTS.summary_fertility_treatment);
  }

  if (data.didkaryotype === "yes") {
    fullList.push(DOCUMENTS.didkaryotype);
  }

  if (data.anyGeneticsIssue === "yes") {
    fullList.push(DOCUMENTS.anyGeneticsIssue);
  }

  if (data.didGeneticTest === "yes") {
    fullList.push(DOCUMENTS.didGeneticTest);
  }
  // האם נלקחה דגימה מהעובר (ביופסיה)

  if (data.pastPregnancy.some((pragnany) => pragnany.embryosSample === "yes")) {
    fullList.push(DOCUMENTS.embryosSample);
  }

  if (
    data.pastPregnancy.some((pragnany) => pragnany.embryosSample === "maybe")
  ) {
    fullList.push(DOCUMENTS.embryosSampleMaybe);
  }

  //didkaryotype

  return fullList;
}
