import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";

export const PragncacyDetails: React.FC<
  ConditionalFormProps & { index: number }
> = (props) => {
  const howEnded = props.watch(`pastPregnancy.${props.index}.howEnded`);

  return (
    <>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            תאריך ווסת אחרונה (תחילת ההריון)
          </FormLabel>

          <Controller
            name={`pastPregnancy.${props.index}.startDate`}
            control={props.control}
            render={({ field }) => (
              <TextField type="date" size="small" {...field} />
            )}
          />
        </FormControl>

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            סוג ההריון
          </FormLabel>
          <Controller
            name={`pastPregnancy.${props.index}.type`}
            control={props.control}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="howEnded-select-small"
                >
                  <MenuItem value={"spontaneous"}>הריון ספונטני </MenuItem>
                  <MenuItem value={"insemination"}>
                    הריון שהושג בהזרעה{" "}
                  </MenuItem>
                  <MenuItem value={"ivf"}>
                    הריון שהושג ע״י הפריה חוץ גופית{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </FormControl>

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            כיצד הסתיים ההריון ?
          </FormLabel>
          <Controller
            name={`pastPregnancy.${props.index}.howEnded`}
            control={props.control}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="howEnded-select-small"
                >
                  <MenuItem value={"EarlyMiscarriage"}>
                    הפלה ספונטנית מוקדמת (עד שבוע 12)
                  </MenuItem>
                  <MenuItem value={"LateMiscarriage"}>
                    הפלה ספונטנית מאוחרת (שבוע 12-20)
                  </MenuItem>
                  <MenuItem value={"earlyBirth"}>
                    לידה מוקדמת (שבוע 20-37)
                  </MenuItem>
                  <MenuItem value={"birth"}> לידה רגילה </MenuItem>
                  <MenuItem value={"IntentionalAbortion"}>
                    {" "}
                    הפלה יזומה{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </FormControl>
      </FormControl>

      {(howEnded === "EarlyMiscarriage" ||
        howEnded === "LateMiscarriage" ||
        howEnded === "earlyBirth") && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            האם נלקחה דגימה מהעובר?
          </FormLabel>
          <Controller
            name={`pastPregnancy.${props.index}.embryosSample`}
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="לא יודעת"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      )}
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם סבלת מסוכרת הריון?
        </FormLabel>
        <Controller
          name={`pastPregnancy.${props.index}.hadDibetes`}
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם סבלת מרעלת הריון ?
        </FormLabel>
        <Controller
          name={`pastPregnancy.${props.index}.PreEclampsia`}
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </>
  );
};
