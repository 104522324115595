import { AWS } from "./aws-config";

// Configure AWS with environment variables
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  ),
});

const s3 = new AWS.S3();

function getEnvVariable(name) {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
}

const config = {
  s3: {
    bucket: getEnvVariable("REACT_APP_S3_BUCKET"),
  },
  ses: {
    region: getEnvVariable("REACT_APP_AWS_REGION"), // May differ from your S3 region
  },
};

export const saveData = (key, value) => {
  const params = {
    Bucket: config.s3.bucket,
    Key: key,
    Body: JSON.stringify(value),
  };

  return s3
    .putObject(params)
    .promise()
    .catch((error) => {
      console.error("Error saving data to S3:", error);
      throw error;
    });
};

export const fetchData = (key) => {
  const params = {
    Bucket: config.s3.bucket,
    Key: key,
  };

  return s3
    .getObject(params)
    .promise()
    .then((response) => JSON.parse(response.Body.toString()))
    .catch((error) => {
      console.error("Error fetching data from S3:", error);
      throw error;
    });
};
