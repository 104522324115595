import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import Home from "./pages/HomePage";
import Questions from "./pages/HelathFormPage/Questions";
import Forum from "./pages/WomensForumPage/Forum";
import { AppBar, Toolbar, Button, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, purple } from "@mui/material/colors";
import "bootstrap/dist/css/bootstrap.min.css";
import "./customCss.css";
import logo from "./assets/logo1.png";
import logo2 from "./assets/logo2.png";
import "./fonts.css";
import { ContactUsDialog } from "./pages/Shared/ContactUsDialog";

const theme = createTheme({
  typography: {
    fontFamily: "times-bold-italic",
    h6: {
      color: "#123c42", // Set the text color for the typography
      fontSize: "1.5rem", // Increase the font size
      //fontFamily: "CustomFont", // palatino
      fontFamily: "'Arimo', sans-serif",
    },
    h5: {
      color: "#123c42", // Set the text color for the typography
      //  fontSize: "1.5rem", // Increase the font size
      fontFamily: "'Arimo', sans-serif",
    },
  },
  direction: "rtl", // Right to left layout
  palette: {
    primary: {
      main: "#e45c8c", // This can be adjusted or left as is
    },
    secondary: {
      main: "#23bcc9",
    },
    success: {
      main: "#23bcc9",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff", // Set AppBar background to white
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#123c42", // Set button text color
          fontSize: "1.2rem", // Increase the button text size
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#123c42", // Ensure all typography text is this color
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "100px", // Increase the height of the toolbar
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.8rem", // Set the font size smaller
          color: "#123c42", // Set the label text color
        },
      },
    },
  },
});

function App() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  // Handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <div style={{ fontFamily: 'CustomFont, sans-serif' }}>
    <ThemeProvider theme={theme}>
      <AppBar position="fixed">
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {/* <Box display="flex" alignItems="center" height="60px">
              <Button color="inherit" component={Link} to="/">
                דף בית
              </Button>
              <Button color="inherit" component={Link} to="/category-one">
                שאלון מקיף
              </Button>
              <Button color="inherit" component={Link} to="/category-two">
                פורום שאלות ותשובות
              </Button>
            </Box> */}
            <Box display="flex" alignItems="center">
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: 90,
                  marginRight: 30,
                  marginBottom: 10,
                  marginTop: 5,
                }}
              />
              <Typography variant="h5" className="anotherFontClass">
                המרכז לתמיכה בפוריות
              </Typography>

              <Button
                onClick={handleClickOpen}
                // sx={{ position: "fixed", top: 10, right: 10 }} // Position at the top-right corner
                //   onClick={handleScrollToForm}
              >
                <img
                  src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/contact1.png"
                  alt="Logo"
                  style={{
                    height: 65,
                    marginLeft: 10,
                  }}
                />
              </Button>
            </Box>
            <ContactUsDialog open={open} onClose={handleClose} />
          </Box>
        </Toolbar>
      </AppBar>{" "}
      <Toolbar />
      {/* <img src={logo2} alt="Logo" /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category-one" element={<Questions />} />
        <Route path="/category-two" element={<Forum />} />
      </Routes>
    </ThemeProvider>
    // </div>
  );
}

export default App;
