const Sentence_1 = "פרוטוקול סטנדרטי לא מתאים לכולן";
const Sentence_2 = "כי זאת הזכות שלך להיות אמא";
const Sentence_3 =
  "במידה והטיפול לא מדויק עבורך, את משלמת את המחיר ולא אף אחד אחר";
const Sentence_4 = "כדי לדעת לבקש טיפול שונה, את צריכה לדעת מהן האופציות";
const Sentence_5 = "אי פיריון בלתי מוסבר זאת לא אבחנה";
const Sentence_6 = "הסתכלות הוליסטית של הגוף היא מפתח להצלחת הריון";
const Sentence_8 = "הגיע הזמן שתקבלי את התמונה המלאה על הגוף שלך";
const Sentence_7 = "אל תצפי לתוצאה שונה, אם את עושה שוב ושוב את אותו פרוטוקול";
const Sentence_9 = "את צריכה להיות חלק אקטיבי בקבלת ההחלטות על הגוף שלך";
const Sentence_10 = "כל אישה היא מיוחדת וצריכה לקבל טיפול המותאם בדיוק עבורה";
const Sentence_11 = "הוא לא בהכרח הפתרון האידיאלי IVF";
const Sentence_12 = "ידע הוא כוח";
const Sentence_13 = "את לא חייבת לקחת מינון גבוה של הורמונים";
const Sentence_14 = "לפעמים צריך לקחת צעד אחורה כדי להתקדם 10 קדימה";
const Sentence_15 = "ישנן חלופות לפרוטוקולים הסטנדרטים";
const Sentence_16 = "אבחון מדויק של הבעיה הוא הבסיס להצלחה";
const Sentence_31 = "שינויים קטנים באורך חיים יכולים להביא לתוצאות גדולות";
const Sentence_30 =
  "אורך החיים, תזונה והבריאות שלך קריטיים להצלחה של טיפולי פוריות";
const Sentence_17 = "הטיפול מתחיל הרבה לפני שאת מזריקה הורמונים";
const Sentence_18 = "את יכולה להשפיע על איכות הביציות שלך";
const Sentence_19 = "את יכולה להשפיע על הרזרבה השחלתית שלך";
const Sentence_20 =
  "נטילה לא מדויקת של תוספי תזונה, במקרה הטוב לא תועיל. במקרה הרע- עלולה להזיק";
const Sentence_21 =
  "גם תסמינים כלליים לכאורה לא מדאיגים רלוונטים להצלחה שלך להרות";
const Sentence_22 =
  "גם מצב רפואי שאינו קשור לפוריות, יכול להיות רלוונטי להצלחה שלך להרות";
const Sentence_23 =
  "הגוף שלנו הוא אחד, הכל קשור הכל משפיע. רק בעזרת אבחנה אישית הוליסטית ניתן לחבר את הנקודות";
const Sentence_24 = "ברוב המקרים, אי פיריון הוא תוצר של כמה גורמים";

export const sentences = [
  Sentence_3,
  Sentence_1,
  Sentence_2,
  Sentence_19,
  Sentence_4,
  Sentence_5,
  Sentence_6,
  Sentence_7,
  Sentence_8,
  Sentence_9,
  Sentence_10,
  Sentence_11,
  Sentence_12,
  Sentence_13,
  Sentence_14,
  Sentence_15,
  Sentence_16,
  Sentence_17,
  Sentence_18,
  Sentence_20,
  Sentence_21,
  Sentence_22,
  Sentence_23,
  Sentence_24,
];
