import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StomachSurgery: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("anyStomachSurgery");
  const secondeQuestionAnswer = props.watch("anyUterusTest");

  return (
    <>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם עברת ניתוח גניקולוגי או ניתוח בחלל הבטן?
        </FormLabel>
        <Controller
          name="anyStomachSurgery"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
            </RadioGroup>
          )}
        />
      </FormControl>

      {firstQuestionAnswer === "yes" && (
        <Box
          sx={{
            width: "100%",
            maxWidth: 500,
            margin: "auto",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              איזה ניתוח עברת?{" "}
            </FormLabel>
            <Controller
              name="stomachSurgeryDescription"
              control={props.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <div className="form-group ">
                  <TextField
                    {...field}
                    //   required //error
                    id="outlined-required"
                    size="small"
                  />
                </div>
              )}
            />
          </FormControl>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              מתי בוצע הניתוח?{" "}
            </FormLabel>

            <Controller
              name="stomachSurgeryDate"
              control={props.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <div className="form-group ">
                  <TextField
                    {...field}
                    type="date"
                    //   required //error
                    id="outlined-required"
                    size="small"
                  />
                </div>
              )}
            />
          </FormControl>

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם ביצעת בדיקה לנוכחות הידבקויות בחלל הבטן לאחר הניתוח?
            </FormLabel>
            <Controller
              name="stomachSurgeryStickyTest"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם לאחר הניתוח הופיעו כאבים כרוניים חריגים בחלל הבטן?
            </FormLabel>
            <Controller
              name="stomachSurgeryPains"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>
      )}

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם בוצעה בדיקה להערכת תקינות חלל הרחם והחצוצרות?
        </FormLabel>
        <Controller
          name="anyUterusTest"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      {secondeQuestionAnswer === "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            מתי בוצעה הבדיקה?
          </FormLabel>

          <Controller
            name="uterusTestDate"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                <TextField
                  {...field}
                  type="date"
                  //   required //error
                  id="outlined-required"
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>
      )}
    </>
  );
};
