import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";

export const GeneralInfo: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography variant="h6" component="div" sx={{ mb: 3 }}>
        רקע כללי
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ maxWidth: "500px" }}
      >
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            שם פרטי ושם משפחה
          </FormLabel>
          <Controller
            name="name"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                <TextField
                  {...field}
                  required //error
                  id="outlined-required"
                  fullWidth
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>

        <PhoneNumber control={props.control} watch={props.watch} />
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            דוא"ל{" "}
          </FormLabel>

          <Controller
            name="email"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                <TextField
                  {...field}
                  required //error
                  id="outlined-required"
                  fullWidth
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            תאריך לידה
          </FormLabel>

          <Controller
            name="birthDate"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                <TextField
                  required //error
                  id="outlined-required"
                  type="date"
                  value={value}
                  size="small"
                  onChange={onChange}
                  fullWidth
                  sx={{
                    direction: "rtl",
                    "& input": {
                      textAlign: "right",
                    },
                  }}
                />
              </div>
            )}
          />
        </FormControl>
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            עיר מגורים
          </FormLabel>
          <Controller
            name="city"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                <TextField
                  required //error
                  id="outlined-required"
                  value={value}
                  onChange={onChange}
                  fullWidth
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>
      </Box>
    </>
  );
};
