import { useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Control, UseFormWatch } from "react-hook-form";
import { IFormInputs } from "../../types";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Create an RTL theme
// const theme = createTheme({
//   direction: "rtl", // Right to left
//   components: {
//     MuiTableCell: {
//       styleOverrides: {
//         root: {
//           textAlign: "right", // Align text right in all table cells
//         },
//       },
//     },
//   },
// });

interface Props {
  control: Control<IFormInputs>;
  watch: UseFormWatch<IFormInputs>;
}

export const NutritionTable: React.FC<Props> = ({ control, watch }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "vitamins",
  });

  const firstQuestionAnswer = watch("regularActiveVitamins");
  // Append an empty row on initial render if no items exist
  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: "",
        dose: "",
        frequency: "",
        bloodTested: "",
        startDate: "",
      });
    }
  }, [append, fields.length]);

  return (
    <div dir="rtl">
      <Controller
        name="regularActiveVitamins"
        control={control}
        render={({ field }) => (
          <FormControl
            component="fieldset"
            sx={{ m: 1, minWidth: 120 }}
            size="small"
          >
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם את צורכת תוספי תזונה?
            </FormLabel>
            <Select
              {...field}
              labelId="demo-select-small-label"
              id="demo-select-small"
              sx={{ maxWidth: "500px" }}
            >
              <MenuItem value={"yes"}>כן</MenuItem>
              <MenuItem value={"no"}>לא</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      {firstQuestionAnswer === "yes" && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>תוסף תזונה</TableCell>
                <TableCell>מינון</TableCell>
                <TableCell>תדירות</TableCell>
                <TableCell>האם בוצעה בדיקה למדידת הערך בדם?</TableCell>
                <TableCell>תאריך התחלה</TableCell>
                <TableCell>מחק</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Controller
                      name={`vitamins.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`vitamins.${index}.dose`}
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`vitamins.${index}.frequency`}
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`vitamins.${index}.bloodTested`}
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`vitamins.${index}.startDate`}
                      control={control}
                      render={({ field }) => (
                        <TextField type="date" {...field} />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            type="button"
            onClick={() => append({ name: "", dose: "", startDate: "" })}
            style={{ marginTop: 20 }}
            variant="contained" // Use contained variant for a button-like appearance
            color="primary" // Use primary color from the theme
            sx={{ marginTop: 20 }} // Add custom styling using sx prop
          >
            הוסיפי תוסף תזונה
          </Button>
        </>
      )}
    </div>
  );
};
