import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import { Disbetes } from "./ConditionalQuestions/Disbetes";
import { CancerQuestions } from "./ConditionalQuestions/CancerQuestions";
import { FatsProfile } from "./FatsProfile";
import { NutritionTable } from "./NutritionTable";
import { Typography, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { getPregnanciesData } from "../FormQuestions/utils/getPregnanciesDats";

import { getListOfNeededDocs } from "../FormQuestions/utils/getListOfNeededDocs";
import { DOCS_STRINGS, DOCUMENTS } from "../../types/DocType";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

interface Props {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
  data: IFormInputs;
}

// export const AllFertilityTreatmentsTable: React.FC<Props> = ({
//   control,
//   watch,
// }) => {

export const ListOfDocumentsSection: React.FC<Props> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
  data: IFormInputs;
}) => {
  const firstQuestionAnswer = props.watch("isNormalTSH");
  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);

  const list: DOCUMENTS[] = getListOfNeededDocs(props.data);
  const listOfDocs = list.map((doc) => DOCS_STRINGS[doc]);
  const pragnancyData = getPregnanciesData(props.data);
  return (
    <>
      <Typography variant="h6" component="div">
        רשימת מסמכים
      </Typography>
      {pragnancyData}
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ maxWidth: "500px" }}
      >
        היוש{" "}
      </Box>
    </>
  );
};
