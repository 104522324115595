import { useForm, FormProvider } from "react-hook-form";
import { IFormInputs } from "../../../types";

export function useMethods() {
  const methods = useForm<IFormInputs>({});

  return methods;
}

export const defaultValues: IFormInputs = {
  isSmoking: "",
  haveHealthyNutrition: "",
  consumeProcessFoodWeeklyBasis: "",
  anySportActivity: "",
  sportEffortLevel: "",
  medicineSensitivity: "",
  regularActiveMedicine: "",
  regularActiveVitamins: "",
  weight: "",
  height: "",
  anyDiabetes: "",
  disbetesType: "",
  anyDiabetesTreatment: "",
  diabetesTreatmentDescription: "",
  triglyceridesLevels: "",
  cholesterol: "",
  nonHDL: "",
  LDL: "",
  HDL: "",
  isNormalTSH: "",
  isAnemia: "",
  isCancer: "",
  cancerDetails: "",
  anyChemo: "",
  anyCancerScreening: "",
  anyCancerBilogicalTreatment: "",
  anydisbetesType1: "no",
  anyIvf_cancer: "",
  checkedSexDisease: "",
  medicines: [],
  vitamins: [],
  chemoDetails: "",
  frozenEmbryosCount_cancer: "",
  frozenEggsCount_cancer: "",
  fertilityTreatment: [], // frozenEmbryosCount: "",
  mensesCycleDuration: 0,
  smallFolliclesCount: 0,
  // frozenEggsCount: number;

  //AUTOIMMUNE STATUS
  autoimmuneDisease: "",
  anyThyroidDisease: "no",
  //hadas needs to clear it
  hashimoto: false,
  graves: false,
  anyVitligo: "no",
  //anyCeliacDisease: "",
  anyCrohnsDisease: "no",
  anyAtopicDermatitis: "no",
  AlopeciaAreata: "no",
  RheumatoidArthritis: "no",
  SLE: "no",
  Scleroderma: "no",
  anyOtherAutoimmune: "no",
  otherAutoimmuneName: "",
  familyHasAutoimmuneDisease: "",
  familyAutoimmuneDiseaseDescription: "",
  // 16 sections- do you suffer from ?
  unkownTiredness: "no",
  skinRash: "no",
  isMedicinHelpsSkinRash: "no",
  edemas: "no",
  oralAphthae: "no",
  migraines: "no",
  oftenFever: "no",
  lymphNodesIssues: "no",
  stomachPains: "no",
  basinPain: "no",
  // anyTestsForBasinInfection: "",
  dryEyes: "no",
  hypercoagulability: "no",

  /// BIRTH HISTORY
  pastPregnancyCount: 0,
  // pastPregnancy: Pregancy[];
  howManySpontaneousMiscarriage: 0,
  pastPregnancy: Array.from({ length: 5 }, () => ({
    chronologicNumber: "",
    startDate: "",
    howEnded: "",
    hadDibetes: "",
    PreEclampsia: "",
    type: "",
    embryosSample: "",
  })),
  neededMedicines: "",
  MedicinesForPastPregnancies: "",
  ChemicalPregnancy: "",
  ChemicalPregnancyCount: 0,
  anyStomachSurgery: "",
  stomachSurgeryDate: "",
  stomachSurgeryDescription: "",
  stomachSurgeryStickyTest: "",
  stomachSurgeryPains: "",

  anyUterusTest: "",
  uterusTestDate: "",

  // IVF TREATMENTS

  currentlyInIVF: "",
  pastIVF: "",
  // fertilityTreatment: FertilityTreatment;
  // fertilityTreatment: Array.from({ length: 5 }, () => ({
  //   type: "",
  //   date: "",
  //   folliclesCount: 0,
  //   eggsCount: 0,
  //   maternalOvulesCount: 0,
  //   qualityEmryos: 0,
  // })),
  currentFrozenEmbryosStatus: "",
  anyFrozenEmbryos: "",
  anyFrozenEggs: "",
  currentFrozenEggsStatus: "",
  // HORMONS

  mensesCycleWellTimed: "",
  // mensesCycleDuration: number;
  mensesCyclePains: "",
  isExtraHairy: "",
  polycysticOvariesDiagnosed: "",
  endometriosisiagnosed: "",
  lowReturnRate: "",
  didAMH: "",
  amh_value: "",
  lh_value: "",
  fsh_value: "",
  estradiol_value: "",
  // smallFolliclesCount: number;

  // GENETIC
  didGeneticTest: "",
  GeneticTestFounding: "",
  autisimInFamily: "",
  anyGeneticsIssue: "",
  didkaryotype: "",
  karyotypeDescription: "",
  // SPERM QUALITY
  didSpernTest: "",
  spermTestPass: "",
  lastSpermTestDate: "",
  sameSpermMale: "",
  spermMaleDescription: "",
};
