import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";

export const CancerQuestions: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("isCancer");
  const secondQuestion = props.watch("anyChemo");
  const thirdQuestion = props.watch("anyIvf_cancer");

  return (
    <>
      <Controller
        name="isAnemia"
        control={props.control}
        render={({ field }) => (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <FormLabel className="bold-label" htmlFor="isAnemia" sx={{ mb: 2 }}>
              האם את סובלת מאנמיה ?
            </FormLabel>
            <Select
              {...field}
              labelId="demo-select-small-label"
              id="isAnemia-select-small"
            >
              <MenuItem value={"yes"}>כן</MenuItem>
              <MenuItem value={"no"}>לא</MenuItem>
              <MenuItem value={"maybe"}>לא יודעת</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="isCancer"
        control={props.control}
        render={({ field }) => (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <FormLabel className="bold-label" htmlFor="isCancer" sx={{ mb: 2 }}>
              האם סבלת או שהנך סובלת ממחלה ממאירה או שאחד מבני משפחתך בדרגה
              ראשונה סבל או סובל ממחלה ממאירה?
            </FormLabel>
            <Select
              {...field}
              labelId="demo-select-small-label"
              id="isCancer-select-small"
            >
              <MenuItem value={"no"}>לא </MenuItem>
              <MenuItem value={"currentMyself"}>
                אני סובלת ממחלה ממאירה
              </MenuItem>
              <MenuItem value={"pastMyself"}>סבלתי בעברי ממחלה ממאירה</MenuItem>
              <MenuItem value={"family"}>
                אחד מבני משפחתי סובל או סבל ממחלה ממאירה
              </MenuItem>
            </Select>
          </FormControl>
        )}
      />
      {(firstQuestionAnswer === "currentMyself" ||
        firstQuestionAnswer === "family") && (
        <Typography variant="h6" component="div">
          אנו ממש מצטערות לשמוע…
        </Typography>
      )}

      {firstQuestionAnswer === "family" && (
        <Controller
          name="cancerDetails"
          control={props.control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              {/* <FormLabel className="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}
              <FormLabel
                className="bold-label"
                htmlFor="anyChemo"
                sx={{ mb: 2 }}
              >
                אנא צייני את סוג הסרטן ודרגת הקרבה שלך לבן המשפחה
              </FormLabel>
              <TextField {...field} id="outlined-required" size="small" />
            </FormControl>
          )}
        />
      )}

      {firstQuestionAnswer === "currentMyself" && (
        <>
          <Controller
            name="cancerDetails"
            control={props.control}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                {/* <FormLabel className="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}
                <FormLabel
                  className="bold-label"
                  htmlFor="anyChemo"
                  sx={{ mb: 2 }}
                >
                  אנא צייני את סוג הסרטן, כולל הדירוג שלו
                </FormLabel>
                <TextField {...field} id="outlined-required" size="small" />
              </FormControl>
            )}
          />
          <Controller
            name="anyChemo"
            control={props.control}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <FormLabel
                  className="bold-label"
                  htmlFor="anyChemo"
                  sx={{ mb: 2 }}
                >
                  האם עברת טיפולי כימותרפיה?
                </FormLabel>
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="anyChemo-select-small"
                >
                  <MenuItem value={"yes"}>כן</MenuItem>
                  <MenuItem value={"no"}>לא</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {secondQuestion === "yes" && (
            // <>
            <Controller
              name="chemoDetails"
              control={props.control}
              render={({ field }) => (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <FormLabel
                    className="bold-label"
                    htmlFor="anyChemo"
                    sx={{ mb: 2 }}
                  >
                    אנא צייני את סוג הכימותרפיה שקיבלת
                  </FormLabel>

                  <TextField
                    {...field}
                    id="outlined-required"
                    label="אנא צייני את סוג הכימותרפיה שקיבלת" //error
                    size="small"
                  />
                </FormControl>
              )}
            />
          )}

          <Controller
            name="anyCancerScreening"
            control={props.control}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <FormLabel
                  className="bold-label"
                  htmlFor="anyCancerScreening"
                  sx={{ mb: 2 }}
                >
                  האם עברת הקרנות ?
                </FormLabel>
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="anyCancerScreening-select-small"
                >
                  <MenuItem value={"yes"}>כן</MenuItem>
                  <MenuItem value={"no"}>לא</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="anyCancerBilogicalTreatment"
            control={props.control}
            render={({ field }) => (
              <FormControl
                // sx={{ m: 1, minWidth: 120 }}
                size="small"
                sx={{ mb: 2 }}
              >
                <FormLabel
                  className="bold-label"
                  htmlFor="anyCancerBilogicalTreatment"
                >
                  האם קיבלת או שאת מקבלת כיום טיפול ביולוגי?
                </FormLabel>
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="anyCancerBilogicalTreatment-select-small"
                >
                  <MenuItem value={"yes"}>כן</MenuItem>
                  <MenuItem value={"no"}>לא</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="anyIvf_cancer"
            control={props.control}
            render={({ field }) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <FormLabel
                  className="bold-label"
                  htmlFor="anyIvf_cancer"
                  sx={{ mb: 2 }}
                >
                  האם ביצעת טיפול לשימור פוריות?
                </FormLabel>
                <Select
                  {...field}
                  labelId="demo-select-small-label"
                  id="anyIvf_cancer-select-small"
                >
                  <MenuItem value={"yes"}>כן</MenuItem>
                  <MenuItem value={"no"}>לא</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {thirdQuestion === "yes" && (
            <>
              <Controller
                name="frozenEmbryosCount_cancer"
                control={props.control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <div className="form-group ">
                    {/* <FormLabel className="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                    <TextField
                      {...field}
                      id="outlined-required"
                      label="כמה עוברים קפואים יש לך היום ? "
                      size="small"
                    />
                  </div>
                )}
              />
              <Controller
                name="frozenEggsCount_cancer"
                control={props.control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <div className="form-group ">
                    {/* <FormLabel className="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                    <TextField
                      {...field}
                      id="outlined-required"
                      label="כמה ביציות קפואות יש לך היום?" //error
                      size="small"
                    />
                  </div>
                )}
              />
            </>
          )}
        </>

        // </>
      )}
    </>
  );
};
