import React from "react";
import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";

// Define the props interface
interface ContactUsDialogProps {
  open: boolean;
  onClose: () => void;
}

interface FormInputs {
  name: string;
  email: string;
  phone: string;
}

export const ContactUsDialog: React.FC<ContactUsDialogProps> = ({
  open,
  onClose,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormInputs>();

  const onSubmit = async (data: FormInputs) => {
    try {
      const response = await fetch(
        "https://ny9rfgux50.execute-api.eu-north-1.amazonaws.com/prod/send-email",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert("המידע שלך נשלח בהצלחה!");
      } else {
        alert("שליחת המידע נכשלה. נסי שוב.");
      }
    } catch (error) {
      alert("קרתה שגיאה בשליחת המידע. נסי שוב.");
    }

    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ direction: "rtl" }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center", // Center the content horizontally
          alignItems: "center", // Center the content vertically
          paddingRight: 2,
          position: "relative", // To properly position the close button
        }}
      >
        <Box display="flex" justifyContent="center">
          <img
            src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/waiting3.png"
            alt="Logo"
            style={{
              height: 80,
              marginBottom: 10,
              marginTop: 15,
            }}
          />
        </Box>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              padding: "16px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "שמך המלא הינו חובה" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder="הזיני את שמך"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontFamily: "'Rubik', sans-serif",
                        fontSize: "18px",
                      },
                    },
                    style: { textAlign: "right", direction: "rtl" },
                  }}
                  FormHelperTextProps={{
                    sx: { textAlign: "right", direction: "rtl" }, // Align error message to the right
                  }}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "כתובת המייל הינה חובה",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "כתובת מייל לא תקינה",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder="מייל"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontFamily: "'Rubik', sans-serif",
                        fontSize: "18px",
                      },
                    },
                    style: { textAlign: "right", direction: "rtl" },
                  }}
                  FormHelperTextProps={{
                    sx: { textAlign: "right", direction: "rtl" }, // Align error message to the right
                  }}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{ required: "מספר הטלפון הינו חובה" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder="הזיני את מספר הטלפון שלך"
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ""}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontFamily: "'Rubik', sans-serif",
                        fontSize: "18px",
                      },
                    },
                    style: { textAlign: "right", direction: "rtl" },
                  }}
                  FormHelperTextProps={{
                    sx: { textAlign: "right", direction: "rtl" }, // Align error message to the right
                  }}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            direction: "rtl",
            justifyContent: "flex-start",
            paddingRight: 4,
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{
              backgroundColor: "#1c7d83", // Change the button color to a custom color
              fontFamily: "'Rubik', sans-serif", // Use the same font as the form
              fontSize: "16px", // Set font size to match the form
              color: "white", // Text color
              "&:hover": {
                backgroundColor: "#e55d8d", // Change hover color if desired
              },
            }}
          >
            שלחי
          </Button>
          <Button
            onClick={onClose}
            color="primary"
            sx={{
              fontFamily: "'Rubik', sans-serif", // Use the same font as the form
              fontSize: "16px", // Set font size to match the form
            }}
          >
            ביטול
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
