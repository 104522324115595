import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { GeneralDiseases } from "./ConditionalQuestions/GeneralDiseases";
import { Disbetes } from "./ConditionalQuestions/Disbetes";
import { CancerQuestions } from "./ConditionalQuestions/CancerQuestions";
import { YourAutoimmuneDiseaseRadio } from "./ConditionalQuestions/YourAutoimmuneDiseaseRadio";
import { AllFertilityTreatmentsTable } from "./AllFertilityTreatmentsTable";
import { Typography } from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const Sperm: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("didSpernTest");
  const secondeQuestionAnswer = props.watch("sameSpermMale");

  return (
    <>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם בוצעה בדיקת זרע{" "}
        </FormLabel>
        <Controller
          name="didSpernTest"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
            </RadioGroup>
          )}
        />
      </FormControl>

      {firstQuestionAnswer === "yes" && (
        <>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              {" "}
              האם בדיקת הזרע הינה תקינה ?{" "}
            </FormLabel>
            <Controller
              name="spermTestPass"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              מתי בוצעה בדיקת הזרע האחרונה?{" "}
            </FormLabel>
            <Controller
              name="lastSpermTestDate"
              control={props.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <div className="form-group ">
                  <TextField
                    {...field}
                    type="date"
                    id="outlined-required"
                    size="small"
                  />
                </div>
              )}
            />
          </FormControl>
        </>
      )}

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם כל הניסיונות להרות או ההריונות עצמם הינם עם מאותו בן זוג/תורם זרע?{" "}
        </FormLabel>
        <Controller
          name="sameSpermMale"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
            </RadioGroup>
          )}
        />
      </FormControl>

      {secondeQuestionAnswer === "no" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא פרטי{" "}
          </FormLabel>
          <Controller
            name="spermMaleDescription"
            control={props.control}
            //  rules={{ required: true, min: 0, max: 100 }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                // label="Number Input"
                variant="outlined"
                fullWidth
                size="small"
              />
            )}
          />
        </FormControl>
      )}
    </>
  );
};
