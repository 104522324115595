import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const HealthyNutrition: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("haveHealthyNutrition");
  return (
    <>
      <Controller
        name="haveHealthyNutrition"
        control={props.control}
        render={({ field }) => (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <FormLabel
              sx={{ mb: 2 }}
              className="bold-label"
              htmlFor="haveHealthyNutrition"
            >
              האם את מקפידה על תזונה מאוזנת?
            </FormLabel>
            <Select
              {...field}
              labelId="demo-select-small-label"
              id="haveHealthyNutrition-select-small"
            >
              <MenuItem value={"yes"}>כן</MenuItem>
              <MenuItem value={"no"}>לא</MenuItem>
              <MenuItem value={"maybe"}>לא יודעת</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      {(firstQuestionAnswer === "no" || firstQuestionAnswer === "maybe") && (
        <Controller
          name="consumeProcessFoodWeeklyBasis"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <FormLabel
                className="bold-label"
                htmlFor="haveHealthyNutrition"
                sx={{ mb: 2 }}
              >
                האם את צורכת מזון מעובד על בסיס שבועי?
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="consumeProcessFoodWeeklyBasis-select-small"
              >
                <MenuItem value={"yes"}>כן</MenuItem>
                <MenuItem value={"no"}>לא</MenuItem>
                <MenuItem value={"maybe"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      )}
    </>
  );
};
