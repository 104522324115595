import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import { LastFivePregnancies } from "./LastFivePregnancies";
import { StomachSurgery } from "./ConditionalQuestions/StomachSurgery";
import { MedicineForm } from "./MedicineTable";
import { Sperm } from "./Sperm";
import { Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const GeneticsAndSpermSection: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("anyGeneticsIssue");
  const secondeQuestionAnswer = props.watch("didkaryotype");

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography variant="h6" component="div">
        רקע גנטי ואיכות הזרע
      </Typography>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם בוצעה בדיקת סקר גנטי רחב?
        </FormLabel>
        <Controller
          name="didGeneticTest"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם אחד מבני הזוג חולה או נשא של מחלה גנטית?
        </FormLabel>
        <Controller
          name="anyGeneticsIssue"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      {firstQuestionAnswer == "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא פרטי{" "}
          </FormLabel>
          <Controller
            name="GeneticTestFounding"
            control={props.control}
            //  rules={{ required: true, min: 0, max: 100 }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                // label="Number Input"
                variant="outlined"
                fullWidth
                size="small"
              />
            )}
          />
        </FormControl>
      )}

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם במשפחה הקרובה יש מקרים של אוטיזם?
        </FormLabel>
        <Controller
          name="autisimInFamily"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם בוצעה בדיקת קריוטיפ של שני בני הזוג?{" "}
        </FormLabel>
        <Controller
          name="didkaryotype"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
            </RadioGroup>
          )}
        />
      </FormControl>

      {secondeQuestionAnswer === "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא פרטי על תוצאות הבדיקה{" "}
          </FormLabel>
          <Controller
            name="karyotypeDescription"
            control={props.control}
            //  rules={{ required: true, min: 0, max: 100 }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                // label="Number Input"
                variant="outlined"
                fullWidth
                size="small"
              />
            )}
          />
        </FormControl>
      )}

      <Sperm control={props.control} watch={props.watch} />
    </>
  );
};
