import { IFormInputs } from "../../../types";
import { DOCUMENTS } from "../../../types/DocType";
import { Pregancy } from "../../../types";

export enum PastPregnanciesDocs {
  birth_summary,
  pregnancy_summary,
  screening,
  summary_initiated_miscarriage,
  summary_non_initiated_miscarriage,
  medical_termination_report,
  Hospital_discharge_report,
  insemination_protocol,
  fetal_systems,
  embryo_retrieval_protocol,

  // TSH related
  birth_blood_test,
  first_trimester_tsh,
  beginning_of_pregnancy_tsh,

  // immune
  disease_start,
  beginning_of_pregnancy_immune_blood,
  birth_blood_immune_blood,
  close_to_miscarriage_blood_immune,
}

// key - value
// date - [][]

function getPregnancyType(type: any): string {
  switch (type) {
    case "spontaneous":
      return "ספונטני";
    case "insemination":
      return "הזרעה";
    case "ivf":
      return "הפריה חוץ גופנית";
    default:
      return "";
  }
}

function getPregnancyFinalJSX(pregnancy: any): JSX.Element {
  const pregnancyType = getPregnancyType(pregnancy.type);
  return (
    <>
      <p> להריון {pregnancyType}</p> <br />
    </>
  );
}

export function getPregnanciesData(data: IFormInputs): JSX.Element[] {
  let pregnancyData: JSX.Element[] = [];

  data.pastPregnancy.forEach((pregnancy) => {
    const summaryString = getPregnancyFinalJSX(pregnancy); // Correctly pass the whole pregnancy object
    pregnancyData.push(summaryString);
  });

  return pregnancyData;
}

// data.pastPregnancy
// if pastPragnancies > 0 => pastPragnancies []
// go through all preganancies
// if spontanious
// if birth
// create summary string
//   birth_summary,
// pregnancy_summary,
//  if (data.isNormalTSH === "no") {
