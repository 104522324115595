import AWS from "aws-sdk";

function getEnvVariable(name: string): string {
  console.log("REACT_APP_AWS_REGION:", process.env.REACT_APP_AWS_REGION);
  console.log(
    "REACT_APP_AWS_ACCESS_KEY_ID:",
    process.env.REACT_APP_AWS_ACCESS_KEY_ID
  );
  console.log(
    "REACT_APP_AWS_SECRET_ACCESS_KEY:",
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  );
  console.log("REACT_APP_S3_BUCKET:", process.env.REACT_APP_S3_BUCKET);

  const value = process.env[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
}

const config = {
  s3: {
    region: getEnvVariable("REACT_APP_AWS_REGION"),
    credentials: new AWS.Credentials(
      getEnvVariable("REACT_APP_AWS_ACCESS_KEY_ID"),
      getEnvVariable("REACT_APP_AWS_SECRET_ACCESS_KEY")
    ),
    bucket: getEnvVariable("REACT_APP_S3_BUCKET"),
  },
  ses: {
    region: getEnvVariable("REACT_APP_AWS_REGION"), // May differ from your S3 region
  },
};

export { AWS, config };
