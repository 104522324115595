import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import { HealthyNutrition } from "./ConditionalQuestions/HealthyNutrition";
import { SportActivity } from "./ConditionalQuestions/SportActivity";
import { MedicineForm } from "./MedicineTable";
import { NutritionTable } from "./NutritionTable";
import { Typography, Box } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

export const GeneralHealth: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography variant="h6" component="div" sx={{ mb: 3 }}>
        מצב בריאותי כללי ואורח חיים
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ maxWidth: "500px" }}
      >
        <Controller
          name="weight"
          control={props.control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                משקל
              </FormLabel>

              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                <TextField {...field} size="small" />
              </div>
            </FormControl>
          )}
        />
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            גובה
          </FormLabel>

          <Controller
            name="height"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">שם מלא</FormLabel> */}

                <TextField
                  {...field}
                  size="small"
                  required //error
                />
              </div>
            )}
          />
        </FormControl>
        <HealthyNutrition control={props.control} watch={props.watch} />
        <SportActivity control={props.control} watch={props.watch} />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <MedicineForm control={props.control} watch={props.watch} />
        <NutritionTable control={props.control} watch={props.watch} />
      </Box>
    </>
  );
};
