import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm, FormProvider } from "react-hook-form";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime, purple } from "@mui/material/colors";
import { IFormInputs } from "../../types";
import { GeneralInfo } from "../FormQuestions/GeneralInfoSection";
import { GeneralHealth } from "../FormQuestions/GeneralHealthSection";
import { MetoboliStatusSection } from "../FormQuestions/MetoboliStatusSection";
import { ImmuneStatusSection } from "../FormQuestions/ImmuneStatusSection";
import { BirthBackgroundSection } from "../FormQuestions/BirthBackgroundSection";
import { FertilityTreatmentsSection } from "../FormQuestions/FertilityTreatmentsSection";
import { HormonsSection } from "../FormQuestions/HormonsSection";
import { GeneticsAndSpermSection } from "../FormQuestions/GeneticsAndSpermSection";
import { ListOfDocumentsSection } from "../FormQuestions/ListOfDocumentsSection";

import { getListOfNeededDocs } from "../FormQuestions/utils/getListOfNeededDocs";
import { DOCS_STRINGS, DOCUMENTS } from "../../types/DocType";
import { saveData, fetchData } from "../FormQuestions/services/StorageSerive";
import { sendEmail } from "../FormQuestions/services/EmailService";
import logo1 from "../../assets/logo1.png";
import arrow from "../../assets/arrow.png";
import { getPregnanciesData } from "../FormQuestions/utils/getPregnanciesDats";
import {
  TextField,
  Button,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";

import { useMethods, defaultValues } from "../FormQuestions/utils/getMethods";

const theme = createTheme({
  typography: {
    fontFamily: "Arial",
    h6: {
      color: "#1c7d83", // Set the text color for Typography variant h6
    },
  },
  direction: "rtl", // Right to left layout
  palette: {
    primary: {
      main: "#eca8b3", // Should be a valid CSS color
    },
    secondary: {
      main: "#23bcc9",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#123c42", // Set the text color for FormLabel
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.8rem", // Set the font size smaller
          color: "#23bcc9", // Set the text color for FormControlLabel
        },
      },
    },
  },
});

let i = 1;
const creatUserId = () => i++;
const handleSaveToS3 = async (data: IFormInputs) => {
  try {
    await saveData(creatUserId().toString(), data);
    alert("Data saved successfully to S3");

    // await fetchData();
  } catch (error) {
    alert("Failed to save data");
    console.error("Error saving data to S3:", error);
  }
};

const ConditionalForm: React.FC = () => {
  const methods = useMethods();
  const [userId, setUserId] = useState("");
  const [step, setStep] = useState(1);
  const { handleSubmit, control, watch, trigger, reset } = methods;
  const [calculatedData, setCalculatedData] =
    useState<IFormInputs>(defaultValues);

  useEffect(() => {
    // Check if the user ID is already in local storage
    let id = localStorage.getItem("userId");
    // TODO !!!! add default velues to here. (use reset insted of default)
    let formData: IFormInputs = defaultValues;

    if (!id) {
      // If not, generate a new ID and store it
      id = uuidv4();
      localStorage.setItem("userId", id);
      // reset(defaultValues);
    } else {
      // If ID exists, check for stored form data
      const storedData = localStorage.getItem("formData");
      if (storedData) {
        formData = JSON.parse(storedData);
      }
    }

    // Set the ID in the state
    setUserId(id);

    // Populate the form with stored data if available
    reset(formData);
  }, [reset]);

  //set fake state

  const onSubmit = (data: IFormInputs) => {
    console.log("ON NEXTA ALSO" + data);
    console.log(data);
    let formData: IFormInputs | null = null;

    const storedData = localStorage.getItem("formData");
    if (storedData) {
      formData = JSON.parse(storedData);
    }

    // Merge current step data with existing data
    const updatedData = { ...formData, ...data };

    // Save merged data back to local storage
    localStorage.setItem("formData", JSON.stringify(updatedData));
    const pregnancyJSX = getPregnanciesData(data);
    console.log(JSON.stringify(pregnancyJSX[0]));
    // const list: DOCUMENTS[] = getListOfNeededDocs(data);
    // const listOfDocs = list.map((doc) => DOCS_STRINGS[doc]);

    setCalculatedData(data);
    handleSaveToS3(data);
    setStep(9);
    // Process data or set final results based on answers
  };

  const handleNext = async () => {
    const validate = await trigger(step === 1 ? ["name", "phoneNumber"] : []);
    if (validate && step <= totalSteps) {
      setStep((current) => current + 1);
    }
    handleSubmit(onSubmit)();
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((current) => current - 1);
    }
  };

  const totalSteps = 8; // Assuming there are two steps
  const isSubmit = step === totalSteps - 1;
  const progress = (step / totalSteps) * 100;
  console.log("progress" + progress + "step: " + step + "total" + totalSteps);

  return (
    <ThemeProvider theme={theme}>
      {/* <img src={logo1} alt="Description of my image" /> */}

      <Box
        sx={{ width: "100%", mb: 2, display: "flex", justifyContent: "center" }}
      >
        <Box sx={{ width: "50%", maxWidth: 300, mb: 2 }}>
          <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
            עמוד {step} מתוך {totalSteps}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: 10 }}
          />
        </Box>
      </Box>

      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "center", mb: 2 }}
      >
        <Box
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          {step === totalSteps ? (
            <Button
              type="submit"
              sx={{ fontWeight: "bold" }}
              onClick={handleNext}
            >
              סיימתי לענות
            </Button>
          ) : (
            <Button
              type="submit"
              onClick={handleNext}
              sx={{ fontWeight: "bold" }}
            >
              <Box display="flex" alignItems="center">
                <img
                  src={arrow}
                  alt="arrow"
                  style={{
                    marginRight: 8,
                    transform: "scaleX(-1)",
                    width: 20,
                    height: 20,
                  }}
                />
                הבא
              </Box>
            </Button>
          )}
          {step > 0 && (
            <Button onClick={handleBack} sx={{ fontWeight: "bold" }}>
              <Box display="flex" alignItems="center">
                קודם
                <img
                  src={arrow}
                  alt="arrow"
                  style={{
                    marginLeft: 8,
                    width: 20,
                    height: 20,
                  }}
                />
              </Box>
            </Button>
          )}
        </Box>
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="container mt-4 rtl">
          {step === 1 && <GeneralInfo control={control} watch={watch} />}
          {step === 2 && <GeneralHealth control={control} watch={watch} />}
          {step === 3 && (
            <MetoboliStatusSection control={control} watch={watch} />
          )}
          {step === 4 && (
            <ImmuneStatusSection control={control} watch={watch} />
          )}
          {step === 5 && (
            <BirthBackgroundSection control={control} watch={watch} />
          )}
          {step === 6 && (
            <FertilityTreatmentsSection control={control} watch={watch} />
          )}
          {step === 7 && <HormonsSection control={control} watch={watch} />}
          {step === 8 && (
            <GeneticsAndSpermSection control={control} watch={watch} />
          )}
          {step === 9 && (
            <ListOfDocumentsSection
              control={control}
              watch={watch}
              data={calculatedData}
            />
          )}
        </form>
        <Box
          sx={{
            width: "100%",
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "50%", maxWidth: 300, mb: 2, mt: 10 }}>
            <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
              עמוד {step} מתוך {totalSteps}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ height: 10 }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Box
            sx={{
              width: "300px",
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            {step === totalSteps ? (
              <Button type="submit" sx={{ fontWeight: "bold" }}>
                סיימתי לענות
              </Button>
            ) : (
              <Button
                type="submit"
                onClick={handleNext}
                sx={{ fontWeight: "bold" }}
              >
                <Box display="flex" alignItems="center">
                  <img
                    src={arrow}
                    alt="arrow"
                    style={{
                      marginRight: 8,
                      transform: "scaleX(-1)",
                      width: 20,
                      height: 20,
                    }}
                  />
                  הבא
                </Box>
              </Button>
            )}
            {step > 0 && (
              <Button onClick={handleBack} sx={{ fontWeight: "bold" }}>
                <Box display="flex" alignItems="center">
                  קודם
                  <img
                    src={arrow}
                    alt="arrow"
                    style={{
                      marginLeft: 8,
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
              </Button>
            )}
          </Box>
        </Box>
      </FormProvider>
    </ThemeProvider>
  );
};

export default ConditionalForm;
