import React, { useState, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Typography,
  TextField,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
//import "../fonts.css";
import "../fontRubik.css";
import ImageCarousel from "./ImageCarousel";
import logo from "../assets/logo1.png";
import CloseIcon from "@mui/icons-material/Close";
import { Team } from "./Team";
import { ContactUsDialog } from "./Shared/ContactUsDialog";

const theme = createTheme({
  typography: {
    fontFamily: "'Arimo', sans-serif",
    h3: {
      color: "#1c7d83",
      // color: "#e45c8c",
    },
    // "'Rubik', Arimo",
    h6: {
      fontFamily: "'Arimo', sans-serif", // Keep consistent typography
      fontSize: "1.1rem", // Increase font size slightly for more prominence
      fontWeight: 300, // Make the font slightly bolder for emphasis
      letterSpacing: "0.05rem", // Add letter spacing for elegance
      lineHeight: 1.5, // Improve readability with more line height
      textShadow: "1px 1px 4px rgba(0, 0, 0, 0.15)", // Subtle shadow for depth
    },
    h5: {
      fontFamily: "'Rubik', sans-serif",

      // fontFamily: "'Arimo', Arimo",

      //   color: "#1c7d83",
    },
  },
  direction: "rtl", // Right to left layout
  palette: {
    primary: {
      main: "#23bcc9",
    },
    secondary: {
      main: "#23bcc9",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#123c42",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.8rem",
          color: "#23bcc9",
        },
      },
    },
  },
});

const Home: React.FC = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [open, setOpen] = useState(false);
  const [openPrices, setOpenPrices] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Handle opening the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOpenPrice = () => {
    setOpenPrices(true);
  };

  // Handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePrice = () => {
    setOpenPrices(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "#f0f4f8", // Set the background color here
          minHeight: "100vh", // Ensure it takes full height of the viewport
          paddingTop: "20px",
        }}
      >
        <ImageCarousel />

        <Container
          maxWidth="md"
          style={{ padding: "40px 10px", direction: "rtl" }}
        >
          <Card
            sx={{
              maxWidth: 800,
              margin: "20px auto",
              borderRadius: "16px",
              overflow: "hidden",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
              padding: "40px", // Add some padding to create space around the image
            }}
          >
            <Typography variant="h6" align="right" sx={{ marginBottom: 1 }}>
              כמו כל תחום בעולם הרפואה, גם טיפולי הפוריות צריכים להיות מותאמים
              לך באופן אישי. דיוק של פרוטוקול הטיפול יכול להוציא אותך מהלופ הכל
              כך קשה של עוד ועוד טיפולי פוריות שמסתיימים ללא הצלחה.
            </Typography>
            <Typography variant="h6" align="right" sx={{ marginBottom: 1 }}>
              במרכז לתמיכה בפוריות המטרה שלנו היא לתת לך את הכלים וללוות אותך
              בדרך על מנת לוודא שהטיפול שאת מקבלת הוא הטיפול הכי מדויק והכי נכון
              עבורך.
            </Typography>
            <Typography variant="h6" align="right" sx={{ marginBottom: 1 }}>
              נלמד להכיר אותך הכי טוב שאפשר - נעבור על כל ההיסטוריה הרפואית שלך
              ונכיר כל בדיקה חריגה. נעבור על כל הבדיקות מטיפולי הפוריות שביצעת
              בעבר ומהם נלמד איך לשפר את התגובה השחלתית והרחמית שלך. נלמד את
              הרגלי התזונה שלך, את אורח החיים שלך, את הצרכים ואת הרצונות שלך.
            </Typography>
            <Typography variant="h6" align="right" sx={{ marginBottom: 1 }}>
              מתוך המקום ההוליסטי הזה, ועם הידע וכלי המחקר שברשותנו, נדע להכווין
              אותך בצורה הכי טובה שניתן בדרכך להיות אמא. טיפולי פוריות הם מסע
              דינמי ואנחנו נהיה לצידך לכל אורכו, עם תמיכה רגשית ומענה מדעי,
              מקצועי, זמין ונגיש לכל שאלה או צורך.
            </Typography>
            <Typography variant="h5" align="right" sx={{ marginTop: 5 }}>
              זה הזמן שלך לקחת אחריות על העתיד שלך ואנחנו כאן כדי ללוות אותך
              בדרך הזאת.
            </Typography>
          </Card>
          <Box
            sx={{
              position: "relative",
              paddingBottom: "56.25%", // Keep the aspect ratio as is
              height: 0,
              overflow: "hidden",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              width: "100vw", // Make the container full-width
              maxWidth: "100%", // Ensure it doesn’t overflow the screen
              margin: "0 auto", // Center it horizontally if needed
            }}
          >
            <video
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
              controls
              autoPlay
              muted
              loop
              poster="path/to/poster-image.jpg"
            >
              <source
                src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/encoded_Video2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={handleClickOpen}
              // sx={{ position: "fixed", top: 10, right: 10 }} // Position at the top-right corner
              //   onClick={handleScrollToForm}
            >
              <img
                src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/contactus.png"
                alt="Logo"
                style={{
                  height: 50,
                  marginRight: 30,
                  marginBottom: 10,
                  marginTop: 5,
                }}
              />
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenPrice}
            // sx={{ position: "fixed", top: 10, right: 10 }} // Position at the top-right corner
            //   onClick={handleScrollToForm}
          >
            פרטים על חבילות הליווי
          </Button>
          {/* Dialog with the form */}
          <Dialog
            open={openPrices}
            onClose={handleClosePrice}
            fullWidth
            sx={{ direction: "rtl" }}
            PaperProps={{
              sx: {
                //  width: isSmallScreen ? '90%' : '50%', // 90% width on small screens, 50% on large
                maxWidth: isSmallScreen ? "lg" : "md", // Remove maxWidth restriction on small screens
                //  margin: 'auto', // Center the dialog
              },
            }}
          >
            <DialogTitle>
              {/* Close button aligned to the right */}
              <IconButton
                aria-label="close"
                onClick={handleClosePrice}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            {/* Image below Typography */}
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/prices4.png"
              alt="Diagram"
              style={{
                maxWidth: "100%",
                height: "auto",
                width: "100%", // Ensure the image fits within the dialog width
              }}
            />
          </Dialog>
          <ContactUsDialog open={open} onClose={handleClose} />
          <Team />
          <Box display="flex" justifyContent="center" sx={{ marginBottom: 5 }}>
            <Button
              onClick={handleClickOpen}
              // sx={{ position: "fixed", top: 10, right: 10 }} // Position at the top-right corner
              //   onClick={handleScrollToForm}
            >
              <img
                src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/contactus.png"
                alt="Logo"
                style={{
                  height: 50,
                  marginRight: 30,
                  marginBottom: 10,
                  marginTop: 5,
                }}
              />
            </Button>
          </Box>
          <Divider
            style={{
              margin: "10px 0",
              backgroundColor: "black",
              height: 1,
            }}
          />
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              maxHeight: {
                xs: 950, // 800px on small screens
                sm: 1300,
                md: 1500, // 1500px on medium to large screens
              },
              overflow: "hidden", // Hide the bottom part of the image
            }}
          >
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/whaTisit4.png"
              alt="Diagram"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "cover", // Ensures the image covers the container
                objectPosition: "top", // Aligns the image to the top
                width: "90%",
                marginTop: "40px",
              }}
            />
          </Box>{" "}
          <Box display="flex" justifyContent="center">
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/why4.png"
              alt="Diagram"
              style={{
                maxWidth: "100%",
                height: "auto",
                marginTop: "40px",
                width: "90%",
                // paddingRight: "40px",
                // paddingLeft: "40px",
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/prices4.png"
              alt="Diagram"
              style={{
                maxWidth: "100%",
                height: "auto",
                marginTop: "40px",
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/facts_3+(2).png"
              alt="Diagram"
              style={{
                maxWidth: "100%",
                height: "auto",
                marginTop: "40px",
                width: "90%",
                // paddingRight: "40px",
                // paddingLeft: "40px",
              }}
            />
          </Box>
          <Divider
            style={{
              marginTop: 40,
              backgroundColor: "black",
              height: 1,
            }}
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
