import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import { Disbetes } from "./ConditionalQuestions/Disbetes";
import { CancerQuestions } from "./ConditionalQuestions/CancerQuestions";
import { FatsProfile } from "./FatsProfile";
import { NutritionTable } from "./NutritionTable";
import { Typography, Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export const MetoboliStatusSection: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("isNormalTSH");
  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography variant="h6" component="div">
        סטאטוס מטבולי
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ maxWidth: "500px" }}
      >
        <Disbetes control={props.control} watch={props.watch} />
        <FatsProfile control={props.control} watch={props.watch} />

        {/* TSH conditional Q */}
        <Controller
          name="isNormalTSH"
          control={props.control}
          render={({ field }) => (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <FormLabel
                className="bold-label"
                htmlFor="isNormalTSH"
                sx={{ mb: 2 }}
              >
                האם לאורך השנים בלוטת התריס (ערכי TSH בדם) שלך מאוזנת?
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="isNormalTSH-select-small"
              >
                <MenuItem value={"yes"}>כן</MenuItem>
                <MenuItem value={"no"}>לא</MenuItem>
                <MenuItem value={"maybe"}>לא יודעת</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        {(firstQuestionAnswer === "no" || firstQuestionAnswer === "maybe") && (
          <Typography variant="h6" component="div">
            אם אין לך מידע לגבי בלוטת התריס אנו מבקשות להשלים את הבדיקה בטרם
            הפגישה. ניתן לבקש הפניה מרופא המשפחה ולציין שהבדיקה הינה לטובת כניסה
            להריון.
          </Typography>
        )}
        {/* TSH conditional DONE */}

        <CancerQuestions control={props.control} watch={props.watch} />
      </Box>
    </>
  );
};
