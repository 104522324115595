import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "../PhoneNumber";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const Disbetes: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("anyDiabetes");
  const secondQuestion = props.watch("disbetesType");
  const thirdQuestion = props.watch("anyDiabetesTreatment");

  return (
    <>
      <Controller
        name="anyDiabetes"
        control={props.control}
        render={({ field }) => (
          <FormControl
            //      component="fieldset"
            sx={{ m: 1, minWidth: 120 }}
            size="small"
          >
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם אובחנת עם מחלת הסכרת ?
            </FormLabel>

            <Select
              {...field}
              labelId="demo-select-small-label"
              id="anyDiabetes-select-small"
            >
              <MenuItem value={"yes"}>כן</MenuItem>
              <MenuItem value={"no"}>לא</MenuItem>
              <MenuItem value={"maybe"}>לא יודעת</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      {firstQuestionAnswer === "yes" && (
        <Controller
          name="disbetesType"
          control={props.control}
          render={({ field }) => (
            <FormControl
              //      component="fieldset"
              sx={{ m: 1, minWidth: 120 }}
              size="small"
            >
              <FormLabel component="legend" sx={{ mb: 2 }}>
                {" "}
                באיזו סוג סכרת אובחנת ?
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="consumeProcessFoodWeeklyBasis-select-small"
              >
                <MenuItem value={"type1"}>type 1</MenuItem>
                <MenuItem value={"type2"}>type 2</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      )}

      {secondQuestion === "type2" && firstQuestionAnswer === "yes" && (
        <Controller
          name="anyDiabetesTreatment"
          control={props.control}
          render={({ field }) => (
            <FormControl
              //      component="fieldset"
              sx={{ m: 1, minWidth: 120 }}
              size="small"
            >
              <FormLabel component="legend" sx={{ mb: 2 }}>
                {" "}
                האם את מטופלת?{" "}
              </FormLabel>
              <Select
                {...field}
                labelId="demo-select-small-label"
                id="anyDiabetesTreatment-select-small"
              >
                <MenuItem value={"yes"}>כן</MenuItem>
                <MenuItem value={"no"}>לא</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      )}

      {thirdQuestion === "yes" &&
        secondQuestion === "type2" &&
        firstQuestionAnswer === "yes" && (
          <FormControl
            //      component="fieldset"
            sx={{ m: 1, minWidth: 120 }}
            size="small"
          >
            <FormLabel component="legend" sx={{ mb: 2 }}>
              {" "}
              אנא פרטי{" "}
            </FormLabel>

            <Controller
              name="diabetesTreatmentDescription"
              control={props.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <div className="form-group ">
                  <TextField
                    {...field}
                    required //error
                    size="medium"
                    fullWidth
                  />
                </div>
              )}
            />
          </FormControl>
        )}
    </>
  );
};
