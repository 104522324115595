import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { GeneralDiseases } from "./ConditionalQuestions/GeneralDiseases";
import { Disbetes } from "./ConditionalQuestions/Disbetes";
import { CancerQuestions } from "./ConditionalQuestions/CancerQuestions";
import { YourAutoimmuneDiseaseRadio } from "./ConditionalQuestions/YourAutoimmuneDiseaseRadio";
import { AllFertilityTreatmentsTable } from "./AllFertilityTreatmentsTable";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const FertilityTreatmentsSection: React.FC<
  ConditionalFormProps
> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("currentlyInIVF");
  const secondeQuestionAnswer = props.watch("pastIVF");
  const anyFrozenEmbryos = props.watch("anyFrozenEmbryos");
  const anyFrozenEggs = props.watch("anyFrozenEggs");

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם את נמצאת כעת בטיפולי פוריות?
        </FormLabel>
        <Controller
          name="currentlyInIVF"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
            </RadioGroup>
          )}
        />
      </FormControl>
      {firstQuestionAnswer === "no" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            האם ביצעת טיפולי פוריות בעברך ?
          </FormLabel>
          <Controller
            name="pastIVF"
            control={props.control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel value="no" control={<Radio />} label="לא" />
                <FormControlLabel value="yes" control={<Radio />} label="כן" />
              </RadioGroup>
            )}
          />
        </FormControl>
      )}
      {(firstQuestionAnswer === "yes" || secondeQuestionAnswer === "yes") && (
        <>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא מלאי את הפרטים הרלוונטים לגבי הטיפולים שבוצעו בשנה שנה האחרונה
            או ששת הטיפולים האחרונים.
          </FormLabel>
          <AllFertilityTreatmentsTable
            control={props.control}
            watch={props.watch}
          />

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם יש לך עוברים קפואים ?
            </FormLabel>
            <Controller
              name="anyFrozenEmbryos"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          {anyFrozenEmbryos === "yes" && (
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                מה סטאטוס העוברים הקפואים שברשותך?
              </FormLabel>
              <Controller
                name="currentFrozenEmbryosStatus"
                control={props.control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <div className="form-group ">
                    <TextField
                      {...field}
                      //   required //error
                      id="outlined-required"
                      size="small"
                    />
                  </div>
                )}
              />
            </FormControl>
          )}

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              האם יש לך ביציות קפואות ?
            </FormLabel>
            <Controller
              name="anyFrozenEggs"
              control={props.control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          {anyFrozenEggs === "yes" && (
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                מה סטאטוס הביציות הקפואות שברשותך?
              </FormLabel>
              <Controller
                name="currentFrozenEggsStatus"
                control={props.control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <div className="form-group ">
                    <TextField
                      {...field}
                      //   required //error
                      id="outlined-required"
                      size="small"
                    />
                  </div>
                )}
              />
            </FormControl>
          )}
        </>
      )}
    </>
  );
};
