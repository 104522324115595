import React, { useEffect } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PhoneNumber } from "./PhoneNumber";
import { LastFivePregnancies } from "./LastFivePregnancies";
import { StomachSurgery } from "./ConditionalQuestions/StomachSurgery";
import { MedicineForm } from "./MedicineTable";
import { NutritionTable } from "./NutritionTable";
import { Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const HormonsSection: React.FC<ConditionalFormProps> = (props: {
  control: Control<IFormInputs, any>;
  watch: UseFormWatch<IFormInputs>;
}) => {
  const firstQuestionAnswer = props.watch("lowReturnRate");
  const secondeQuestionAnswer = props.watch("didAMH");

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Typography variant="h6" component="div">
        סדירות המחזור החודשי ומאזן הורמונלי
      </Typography>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם המחזור החודשי שלך סדיר?{" "}
        </FormLabel>
        <Controller
          name="mensesCycleWellTimed"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          מה משך המחזור החודשי הממוצע שלך (בימים) ?
        </FormLabel>
        <Controller
          name="mensesCycleDuration"
          control={props.control}
          //  rules={{ required: true, min: 0, max: 100 }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              // label="Number Input"
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          {" "}
          האם הנך סובלת מכאבים עזים באגן במהלך הווסת או בעת קיום יחסים?
        </FormLabel>
        <Controller
          name="mensesCyclePains"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם הנך סובלת משיעור יתר?{" "}
        </FormLabel>
        <Controller
          name="isExtraHairy"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם אובחנת בעבר עם שחלות פוליציסטיות?{" "}
        </FormLabel>
        <Controller
          name="polycysticOvariesDiagnosed"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם אובחנת בעבר עם אנדומטריוזיס או אדנומיוזיס?
        </FormLabel>
        <Controller
          name="endometriosisiagnosed"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם אובחנת עם כשל שחלתי מוקדם או רזרבה נמוכה?
        </FormLabel>
        <Controller
          name="lowReturnRate"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
              <FormControlLabel
                value="maybe"
                control={<Radio />}
                label="לא יודעת"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      {firstQuestionAnswer === "yes" && (
        <>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              מה ערכי FSH, LH ואסטרוגן (estradiol) כפי שנמדדו ביום 2-5 למחזור
              החודשי?
            </FormLabel>
            <Controller
              name="fsh_value"
              control={props.control}
              //  rules={{ required: true, min: 0, max: 100 }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  label="FSH"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
            <Controller
              name="estradiol_value"
              control={props.control}
              //  rules={{ required: true, min: 0, max: 100 }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  label="אסטרוגן / estradiol "
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
            <Controller
              name="lh_value"
              control={props.control}
              //  rules={{ required: true, min: 0, max: 100 }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  label="LH"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </FormControl>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              {" "}
              האם ביצעת בדיקת AMH ?
            </FormLabel>
            <Controller
              name="didAMH"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel value="no" control={<Radio />} label="לא" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="כן"
                  />
                  <FormControlLabel
                    value="maybe"
                    control={<Radio />}
                    label="לא יודעת"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </>
      )}

      {secondeQuestionAnswer === "yes" && firstQuestionAnswer === "yes" && (
        <>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ mb: 2 }}>
              אנא צייני את הערך המדויק
            </FormLabel>
            <Controller
              name="amh_value"
              control={props.control}
              //  rules={{ required: true, min: 0, max: 100 }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="number"
                  label="AMH"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </FormControl>
        </>
      )}
    </>
  );
};
