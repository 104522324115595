export enum DOCUMENTS {
  GENERAL_BLOOD, //yes
  HORMONS, //yes
  VIROLOGY, //yes
  ONCOLOGIST, //yes
  Autoimmune_antibodies, //ye
  TSH_notNormal, //ye
  TSH_maybe,
  TSH_normal,
  TSH_Autoimmune,
  Immunologist, //ye
  pregnancy_summary,
  general_checks_miscarriage,
  Ultrasound_each_treatment,
  Hormons_each_treatment,
  summary_fertility_treatment,
  stomach_suregery_letter, // yes
  uterus_test,
  sperm_summary_test,
  all_TSH_during_pregnancies,
  stickness_test,
  genetic_test,
  amh,
  afc,

  gastroenterologist_exam,
  SexDisease_bloodTest,
  EarlyMiscarriage_checks,
  LateMiscarriage_checks,
  IntentionalAbortion_checks,
  earlyBirth,
  birth,
  ultrasound_insemination_treatment,
  ultrasound_ivf_treatment,
  summarty_ivf_treatment,
  zeroPragnancy,
  didkaryotype,
  anyGeneticsIssue,
  didGeneticTest,
  embryosSample,
  embryosSampleMaybe,
  // hormonal_check_past_fetility_treatment,

  //high_triglyceridesLevels,
}

export const DOCS_STRINGS = {
  [DOCUMENTS.GENERAL_BLOOD]:
    " שתי בדיקות דם אחרונות הכוללות ספירת דם, סוכרים, שומנים, תפקודי כבד ותפקודי כליה.",
  [DOCUMENTS.HORMONS]:
    " פרופיל הורמונלי שבוצע ביום 2-5 למחזור החודשי (כולל FSH, LH, progesterone, estrogen, 17-OH-progesterone, testosterone, dhea-s, prolactin)",
  [DOCUMENTS.VIROLOGY]: "וירולוגיה - נוגדנים ל- cmv, toxoplasma",
  [DOCUMENTS.ONCOLOGIST]: "סיכום ביקור אחרון אצל אונקולוג",
  [DOCUMENTS.TSH_normal]: "2 מדידות אחרונות של TSH אם יש",
  [DOCUMENTS.Autoimmune_antibodies]:
    "פרופיל נוגדנים אוטואימוניים ומדדי דלקת - C-reactive protein (CRP), erythrocyte sedimentation rate (ESR) and plasma viscosity (PV)",
  [DOCUMENTS.TSH_notNormal]:
    "פרופיל TSH - שינויים ברמת TSH (בדיקות שונות של אותו פרמטר) וגם מדידה של T3 & T4 ",
  [DOCUMENTS.Immunologist]: "סיכום ביקור אחרון אצל רופא אימונולוג ",
  [DOCUMENTS.pregnancy_summary]:
    "סיכום הריון ו/או מכתב שחרור מבית החולים (אם יש) + בדיקות כלליות אם בוצעו במהלך ההריון",
  [DOCUMENTS.general_checks_miscarriage]: "בדיקות כלליות אם בוצעו במהלך ההריון",
  [DOCUMENTS.Ultrasound_each_treatment]:
    "עבור כל טיפול פוריות שבוצע - דו״ח סיכום אולטרסאונד מעקב זקיקים",
  [DOCUMENTS.Hormons_each_treatment]:
    "עבור כל טיפול פוריות שבוצע - מעקב הורמונלי שבוצע במקביל למעקב הזקיקים",
  [DOCUMENTS.summary_fertility_treatment]:
    "עבור כל טיפול פוריות שבוצע - סיכום טיפול (ובו מופיעים הפרוטוקול, מספר הביציות שנשאבו, כמה מהן מטוריות, מספר ההפריות, מספר העוברים)",
  [DOCUMENTS.stomach_suregery_letter]:
    "סיכום ניתוח/מכתב שחרור של ניתוח גניקולוגי / ניתוח בחלל הבטן ",
  [DOCUMENTS.uterus_test]:
    "סיכום תוצאות בדיקה להערכת חלל תקינות הרחם והחצוצרות ",
  [DOCUMENTS.sperm_summary_test]: "סיכום בדיקת זרע",
  // [DOCUMENTS.high_triglyceridesLevels]:
  //   " פרופיל עקב טריגליצרידים מעל לטווח עליון TSH - שינויים ברמת TSH (בדיקות שונות של אותו פרמטר) + T3 & T4",
  [DOCUMENTS.all_TSH_during_pregnancies]: "כל המדידות של TSH בזמן הריונות",
  [DOCUMENTS.stickness_test]:
    "סיכום בדיקה בדיקה להערכת היווצרות הידבקויות לאחר הניתוח",
  [DOCUMENTS.genetic_test]: "תוצאות הסקר הגנטי",
  [DOCUMENTS.amh]: "בדיקת amh (אם יש)",
  [DOCUMENTS.afc]: "אולטרסאונד לספירת זקיקים אנטרליים (afc, אם יש)",

  [DOCUMENTS.gastroenterologist_exam]:
    "סיכום ביקור אחרון אצל רופא מומחה - גסטרולוג ",
  [DOCUMENTS.SexDisease_bloodTest]: "צירף בדיקות הדם ו/או תרבית הרלוונטיות",

  [DOCUMENTS.TSH_maybe]:
    "אם אין לך מידע לגבי בלוטת התריס אנו מבקשות להשלים את הבדיקה בטרם הפגישה. ניתן לבקש הפניה מרופא המשפחה ולציין שהבדיקה הינה לטובת כניסה להריון.",
  [DOCUMENTS.EarlyMiscarriage_checks]:
    "מאחר והיתה לך הפלה ספונטנית מוקדמת, תצרפי בדיקת דם מתחילת ההריון - פרוגסטרון, אסטרוגן, TSH. ",
  [DOCUMENTS.LateMiscarriage_checks]:
    "מאחר והיתה לך הפלה ספונטנית מאוחרת, תצרפי בדיקת דם מתחילת ההריון - פרוגסטרון, אסטרוגן, TSH + בדיקת דם כללית משלב מתקדם יותר של ההריון ",
  [DOCUMENTS.IntentionalAbortion_checks]:
    "מאחר והיתה לך הפלה יזומה, תצרפי מכתב שחרור מבית החולים + דו״ח מעבדת גנטיקה במידה ויש",
  [DOCUMENTS.earlyBirth]:
    "מאחר והיתה לך לידה מוקדמת, תצרפי מכתב שחרור מבית החולים + בדיקות דם שבוצעו במעמד הלידה",
  [DOCUMENTS.birth]:
    "מאחר והיתה לך לידה רגילה, בבקשה תצרפי מכתב שחרור מבית החולים",
  [DOCUMENTS.ultrasound_insemination_treatment]:
    "מאחר והיה לך הריון שהושג בהזרעה, תצרפי מעקב אולטרסאונד (זקיקים ועובי הרירית) ובדיקות דם (אסטרוגן, פרוגסטרון, LH) ",
  [DOCUMENTS.ultrasound_ivf_treatment]:
    "מאחר והיה לך הריון שהושג בהפריה חוץ גופית (IVF), תצרפי מעקב אולטרסאונד (זקיקים ועובי הרירית) ובדיקות דם (אסטרוגן, פרוגסטרון, LH) ",
  [DOCUMENTS.summarty_ivf_treatment]:
    "מאחר והיה לך הריון שהושג בהפריה חוץ גופית (IVF) סיכום טיפול פוריות ובו מופיעים הפרוטוקול, מספר הביציות שנשאבו, כמה מהן מטוריות, מספר ההפריות, מספר העוברים ",
  [DOCUMENTS.zeroPragnancy]:
    "מאחר ולא נכנסת להריון ואת נמצאת כרגע בטיפולי פוריות, תעבירי בבקשה את המסמכים הבאים:",
  [DOCUMENTS.didkaryotype]: "תוצאות בדיקת קריוטיפ של שני בני הזוג",
  [DOCUMENTS.anyGeneticsIssue]:
    "מאחר ואחד מבני הזוג נשא של מחלה גנטית, שלחי דו״ח סיכום ייעוץ גנטיקה",
  [DOCUMENTS.didGeneticTest]: "תוצאות הסקר הגנטי",
  [DOCUMENTS.embryosSample]: "דו״ח מעבדה של פענוח דגימת העובר (ביופסיה)",
  [DOCUMENTS.embryosSampleMaybe]:
    "אנא תבררי מול קופת החולים אם בוצעה ביופסיה ונלקחה דגימה מהעובר ומה סטאטוס הדגימה היום",
  [DOCUMENTS.TSH_Autoimmune]:
    "פרופיל TSH - שינויים ברמת TSH (בדיקות שונות של אותו פרמטר לאורך תקופה) ",
};
