import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { IFormInputs, ConditionalFormProps } from "../../types";
import TextField from "@mui/material/TextField";
import { PragncacyDetails } from "./PragncacyDetails";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography, Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";

// export const LastFivePregnancies: React.FC<ConditionalFormProps> = (props: {
//   control: Control<IFormInputs, any>;
//   watch: UseFormWatch<IFormInputs>;
// }) => {

export const LastFivePregnancies: React.FC<
  ConditionalFormProps & { numberOfPregnancies: number }
> = (props) => {
  const secondeQuestionAnswer = props.watch("neededMedicines");

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          margin: "auto",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {Array.from(
          { length: Math.min(props.numberOfPregnancies, 5) },
          (_, index) => (
            <Box key={index} sx={{ border: "1px solid #ccc", p: 2, mb: 2 }}>
              <PragncacyDetails
                index={index}
                control={props.control}
                watch={props.watch}
              />
              <Divider
                style={{
                  margin: "10px 0",
                  backgroundColor: "black",
                  height: 1,
                }}
              />
            </Box>
          )
        )}
      </Box>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          האם ישנן תרופות קבועות שהתבקשת ליטול במהלך ההריונות (ואשר אינך נוטלת
          בשגרה)
        </FormLabel>
        <Controller
          name="neededMedicines"
          control={props.control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="no" control={<Radio />} label="לא" />
              <FormControlLabel value="yes" control={<Radio />} label="כן" />
            </RadioGroup>
          )}
        />
      </FormControl>
      {secondeQuestionAnswer === "yes" && (
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            אנא פרטי את שמות התרופות:
          </FormLabel>
          <Controller
            name="MedicinesForPastPregnancies"
            control={props.control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div className="form-group ">
                {/* <FormLabelclassName="bold-label" htmlFor="secondQuestion">
                כמה פעמים בשבוע את מתאמנת ?
              </FormLabel> */}

                <TextField
                  {...field}
                  //   required //error
                  id="outlined-required"
                  size="small"
                />
              </div>
            )}
          />
        </FormControl>
      )}
    </>
  );
};
